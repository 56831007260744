import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  children?: React.ReactNode;
  pathname: string;
  title: string;
  description?: string;
  canonical?: string;
  image?: string;
  lang?: string;
}

export default function SEO({
  children,
  pathname,
  title,
  description,
  canonical,
  image,
  lang = 'en',
}: Props) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
        }
      }
    }
  `);
  const metaDescription = description || site.siteMetadata.description;
  const metaKeywords = site.siteMetadata.keywords.join(', ');
  const url = `${site.siteMetadata.siteUrl}${pathname}`;
  const canonicalUrl = canonical
    ? `${site.siteMetadata.siteUrl}${canonical}`
    : url;

  const mainFavicon =
    process.env.NODE_ENV === 'development' ? 'favicon-dev' : 'favicon';

  let cardImage;

  if (image) {
    cardImage = image.includes('http')
      ? image
      : `${site.siteMetadata.siteUrl}${image}`;
  }

  const titleTag = title
    ? `${title} - ${site.siteMetadata.title}`
    : site.siteMetadata.title;
  return (
    <>
      {/* Primary Meta Tags */}
      <title>{titleTag}</title>
      <meta name="title" content={titleTag} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      {/* Canonical */}
      <link rel="canonical" href={canonicalUrl} />
      {/* Favicons */}
      <link
        rel="icon"
        type="image/svg+xml"
        href={`/${mainFavicon}.svg`}
        sizes="any"
      />
      <link rel="icon" href="/favicon.ico" sizes="32x32" />
      <link rel="icon" href="/favicon-128.png" sizes="128x128" />
      <link rel="icon" href="/favicon-192.png" sizes="192x192" />
      {/* Android */}
      <link rel="icon" href="/favicon-196.png" sizes="196x196" />
      {/* iOS */}
      <link rel="apple-touch-icon" href="/favicon-120.png" sizes="120x120" />
      <link rel="apple-touch-icon" href="/favicon-152.png" sizes="152x152" />
      <link rel="apple-touch-icon" href="/favicon-180.png" sizes="180x180" />
      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={cardImage || `${site.siteMetadata.siteUrl}/social-card.jpg`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {/* twitter */}
      <meta property="twitter:title" content={title} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image"
        content={cardImage || `${site.siteMetadata.siteUrl}/social-card.jpg`}
      />
      {children}
    </>
  );
}
