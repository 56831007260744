import { StaticImage } from 'gatsby-plugin-image';
import ShareForm from '../components/Form/ShareForm';
import SEO from '../components/Seo';
import Subscribe from '../components/Subscribe';

export function Head({ location }: { location: { pathname: string } }) {
  return (
    <SEO
      title="Share Your Stories"
      pathname={location.pathname}
      description="Telling the stories of our faculty, students, and alumni is an ideal way to show the impact that Christian colleges and universities have around the world. Submit your stories to us!"
    />
  );
}

export default function SharePage() {
  return (
    <main className="page-share">
      <section className="container section-m-t section-m-b">
        <div className="row">
          <div className="col-xs-12 col-lg-10 col-lg-offset-1">
            <h2 className="m-b">Share your stories!</h2>
            <div className="share__img hide-sm">
              <StaticImage src="../images/share-img.png" alt="" />
            </div>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-6 col-lg-offset-1">
            <div className="share__form">
              <div className="share__form__text narrow">
                <p>
                  Life journeys begin from many different places and settings.{' '}
                </p>
                <p>
                  Telling the stories of our faculty, students, and alumni is an
                  ideal way to show the impact that Christian colleges and
                  universities have around the world.
                </p>
                <p>
                  Thank you for sharing your institution’s stories. The more
                  stories we have the more we build the case for what is
                  distinctive about Christian higher education: academic
                  excellence, lives of service and Christian witness in the
                  world.
                </p>
                <p>
                  <strong>
                    Submit your stories to us so we can share them with the
                    world.
                  </strong>
                </p>
              </div>
              <ShareForm />
            </div>
          </div>
          <div className="col-xs-12 col-md-4 col-lg-5">
            <div className="share__img hide-xs show-sm">
              <StaticImage src="../images/share-img.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Subscribe className="section-m-b" type="share" />
    </main>
  );
}
