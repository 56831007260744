import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { FieldErrors, UseFormGetValues } from 'react-hook-form';

interface Props {
  onChange: (...event: any[]) => void;
  value?: string;
  getValues: UseFormGetValues<any>;
  label: string;
  name: string;
  required?: boolean;
  id: string;
  selected?: string;
  options: { value: string; label: string }[];
  errors: FieldErrors<any>;
}

export default function UniversitySelect({
  onChange,
  value,
  name,
  label,
  required,
  id,
  selected,
  getValues,
  options,
  errors,
}: Props) {
  const [inputItems, setInputItems] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [rawInputValue, setRawInputValue] = useState('');

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    onSelectedItemChange: ({ inputValue }) => onChange(inputValue),
    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue);
      setRawInputValue(inputValue || '');
    },
  });

  function filterInputItems(inputValue: string) {
    setInputItems(
      options
        .filter((option) => {
          const optionMatches = option.value
            .toLowerCase()
            .startsWith(inputValue.toLowerCase());
          if (selected) {
            return (
              option.value.toLowerCase() === selected.toLowerCase() &&
              optionMatches
            );
          }
          return optionMatches;
        })
        .map((option) => option.label)
    );
    setLoading(false);
  }

  useEffect(() => {
    filterInputItems(rawInputValue);
  }, [rawInputValue]);

  const [focused, setFocused] = useState<boolean>(false);

  const isInvalid = !!errors[name];

  return (
    <div className="ds-combobox">
      <div {...getComboboxProps()} className="ds-combobox__input">
        <label htmlFor={id} className="form-field-ui" {...getLabelProps()}>
          <span
            className={`form-field-ui__label ${
              focused || getValues(name) ? 'form-field-ui__label--focused' : ''
            }`}
          >
            {label}
            {required && (
              <>
                {' '}
                <abbr title="required">*</abbr>
              </>
            )}
          </span>
          <input
            id={id}
            name={name}
            className={`form-field-ui__input ${
              focused || getValues(name) ? 'form-field-ui__input--focused' : ''
            }`}
            placeholder="University Name"
            aria-invalid={isInvalid}
            // value={value}
            {...getInputProps({
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false),
            })}
          />
        </label>
        {/* <button
          type="button"
          {...getToggleButtonProps()}
          aria-label="toggle menu"
        /> */}
      </div>
      <ul
        {...getMenuProps()}
        className={`ds-combobox__options ${isOpen ? 'is-open' : ''}`}
      >
        {isOpen &&
          !loading &&
          !!inputItems.length &&
          inputItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index
                  ? {
                      backgroundColor: 'var(--color-accent)',
                      color: 'var(--white)',
                    }
                  : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
        {isOpen && !loading && !inputItems.length && (
          <li>
            <em>Nothing found. </em>
          </li>
        )}
      </ul>
    </div>
  );
}
