import { useState } from 'react';
import { TextAreaFieldProps } from './types';

export default function TextAreaField({
  register,
  getValues,
  validationRules,
  errors,
  containerClassName,
  name,
  label,
  placeholder,
  disabled,
  required,
  ...props
}: TextAreaFieldProps) {
  const [focused, setFocused] = useState<boolean>(false);

  const inputId = props.id || name;

  const [parent, _child] = name.split('[');
  const child = _child ? _child.replace(']', '') : null;

  const isInvalid =
    // eslint-disable-next-line no-nested-ternary
    parent && child
      ? !!(errors[parent] && errors[parent][child])
      : parent
      ? !!errors[parent]
      : false;

  return (
    <div className={containerClassName}>
      <label htmlFor={inputId} className="form-field-ui">
        <span
          className={`form-field-ui__label ${
            focused || getValues(name) ? 'form-field-ui__label--focused' : ''
          }`}
        >
          {label}
          {required && (
            <>
              {' '}
              <abbr title="required">*</abbr>
            </>
          )}
        </span>
        <textarea
          id={inputId}
          className={`form-field-ui__input ${
            focused || getValues(name) ? 'form-field-ui__input--focused' : ''
          }`}
          placeholder={placeholder}
          disabled={disabled}
          aria-invalid={isInvalid}
          onFocus={() => setFocused(true)}
          {...props}
          {...register(name, {
            ...validationRules,
            required,
            onBlur: () => setFocused(false),
          })}
        />
      </label>
    </div>
  );
}
