import LoadingSpinner from '../LoadingSpinner';

interface Props {
  loading: boolean;
  title?: string;
  className?: string;
}

export default function FormSubmitButton({
  loading,
  title,
  className = '',
}: Props) {
  return (
    <button
      type="submit"
      disabled={loading}
      style={{ position: 'relative' }}
      className="form-submit-button"
    >
      <span className={`button ${loading ? 'disabled' : ''} ${className}`}>
        {title || 'Submit'}
      </span>
      {loading && <LoadingSpinner />}
      {/* {icon && <span className="icon">{icon}</span>} */}
    </button>
  );
}
