import { useState } from 'react';
import { SelectFieldProps } from './types';

export default function SelectField({
  register,
  getValues,
  errors,
  name,
  label,
  containerClassName = '',
  required,
  children,
  fixedLabel,
  ...props
}: SelectFieldProps) {
  const [focused, setFocused] = useState<boolean>(false);

  const inputId = props.id || name;

  const [parent, _child] = name.split('[');
  const child = _child ? _child.replace(']', '') : null;

  const isInvalid =
    // eslint-disable-next-line no-nested-ternary
    parent && child
      ? !!(errors[parent] && errors[parent][child])
      : parent
      ? !!errors[parent]
      : false;

  return (
    <div className={containerClassName}>
      {/* Using htmlFor for some reason causes Netlify to not recognize the actual labe, which makes it messy */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={fixedLabel ? '' : 'form-field-ui'}>
        <span
          className={
            fixedLabel
              ? `form-field-label`
              : `form-field-ui__label ${
                  focused || getValues(name)
                    ? 'form-field-ui__label--focused'
                    : ''
                }`
          }
        >
          {label}
          {required && (
            <>
              {' '}
              <abbr title="required">*</abbr>
            </>
          )}
        </span>
        <div className="select-wrapper">
          <select
            id={inputId}
            className={
              fixedLabel
                ? ``
                : `form-field-ui__input ${
                    focused || getValues(name)
                      ? 'form-field-ui__input--focused'
                      : ''
                  }`
            }
            aria-invalid={isInvalid}
            onFocus={() => setFocused(true)}
            style={{ color: focused || getValues(name) ? '' : 'transparent' }}
            {...register(name, {
              required,
              onBlur: () => setFocused(false),
            })}
            {...props}
          >
            {children}
          </select>
        </div>
      </label>
    </div>
  );
}
