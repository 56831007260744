import { useContext, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';
import { CF_WORKER_URL } from '../../utils/config';
import { ModalContext } from '../ModalContext';
import { SnackbarContext } from '../SnackbarContext';
import { DropzoneField } from './DropzoneField';
import FormSubmitButton from './FormSubmitButton';
import InputField from './InputField';
import SelectField from './SelectField';
import TextAreaField from './TextAreaField';
import UniversitySelect from './UniversitySelect';

export default function ShareForm() {
  const [loading, setLoading] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [images, _setImages] = useState<File[]>([]);
  const { setModal } = useContext(ModalContext);
  const { addSnackbar } = useContext(SnackbarContext);

  const [universities, setUniversities] = useState(null);

  useEffect(() => {
    fetch(`${CF_WORKER_URL}/sheets`)
      .then((res) => res.json())
      .then((data) => {
        data.shift(); // Remove header

        setUniversities(
          data.map((row: string[]) => {
            const [name, ...rest] = row;
            return {
              value: `${name} (${rest.join(', ')})`,
              label: `${name} (${rest.join(', ')})`,
            };
          })
        );
      });
  }, []);

  const openDisclaimer = () => {
    setModal({
      modalOpen: true,
      children: (
        <div className="disclaimer-modal">
          <div className="disclaimer-modal__title">
            <h5>Discalimer:</h5>
          </div>
          <div className="disclaimer-modal__body">
            <p>The Submissions MUST:</p>
            <p>
              (i) be true and accurate (except that names of others, locations
              and dates should be changed);
            </p>
            <p>
              (ii) not violate any other individual’s privacy or contain the
              names of others or identifying information;
            </p>
            <p>
              (iii) not require the permission of any third parties to use
              (unless such permission has been given in writing and includes The
              Council for Christian Universities/”CCCU”).
            </p>
            <p>
              I expressly represent, warrant, and promise that my Submission
              meets the foregoing criteria and that I am of legal age and have
              the capacity to bind myself to a contract. CCCU may rely upon the
              accuracy of all details of my Submissions (other than names, dates
              and locations which should be changed to protect privacy),
              particularly the specific details of the circumstances and
              conditions described in my Submissions
            </p>
            <p>
              I grant CCCU, its owners, agents, licensors, successors, and
              assigns and those acting with its authority and permission
              (collectively, “CCCU”), an irrevocable, royalty-free, perpetual,
              and unrestricted license,to:
            </p>
            <p>
              (i) use, re-use, publish, republish, license and sublicense,
              reproduce my Submissions in whole or in part in any and all media,
              including research, now or hereafter known, without attribution,
              notice or inspection;
            </p>
            <p>
              (ii) edit, and/or translate my Submissions provided CCCU makes no
              material changes to my Submissions that would alter the substance
              of the narrative, the artistic expression, or the facts contained
              in my Submissions; and
            </p>
            <p>
              (iii) use the ideas in my Submissions and/or use parts of my
              Submissions, with or without permitted editing or translation, for
              any purposes that are consistent with the mission and purpose of
              CCCU, including but not limited to further publication in any form
              and at any time.
            </p>
            <p>
              I hereby release, discharge, and agree to indemnify and hold
              harmless CCCU from any and all liability, claims, damages, losses
              and the like that CCCU might incur for breach of these
              representations, warranties and promises by me, including without
              limitation, any claims for libel or violation of any right of
              publicity or privacy.
            </p>
            <p>
              I have read the above authorization, release, and agreement, and
              am fully familiar with the contents of this document. I understand
              that this document is binding upon me and my heirs, legal
              representatives, and assigns.
            </p>
          </div>
        </div>
      ),
    });
  };

  const {
    register,
    getValues,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const setImages = (v: File[]) => {
    const list = new DataTransfer();
    v.forEach((file) => list.items.add(file));

    setValue('images', list.files);
    _setImages(v);
  };

  function encode(data: FieldValues) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const d = data[key];
      if (typeof d !== 'undefined' && d !== null) {
        if (d instanceof FileList) {
          for (let i = 0; i < d.length; i += 1) {
            const file = d[i];
            formData.append(key, file, file.name);
          }
        } else if (typeof d === 'object') {
          Object.keys(d).forEach((k) => {
            formData.append(`${key}[${k}]`, d[k]);
          });
        } else {
          formData.append(key, d);
        }
      }
    });
    return formData;
  }

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    // if (data.images?.length) {
    //   for (let i = 0; i < data.images.length; i += 1) {
    //     const file = data.images[i];

    //     const transfer = new DataTransfer();
    //     transfer.items.add(file);
    //     data[`image${i}`] = transfer.files;
    //   }
    // }

    const payload = encode({
      'form-name': 'share',
      ...data,
    });

    // if (process.env.NODE_ENV === 'development') {
    //   console.log('[DEV] Payload', payload);
    //   setLoading(false);
    //   setSuccessful(true);
    //   return;
    // }

    try {
      // Submit to Netlify
      // Needs to be static file under /public folder, cannot be an ISR page
      const res = await fetch(`${CF_WORKER_URL}/submit`, {
        method: 'POST',
        body: payload,
      });

      if (res.ok) {
        setSuccessful(true);
      } else {
        addSnackbar(
          'Sorry, something went wrong. Please try again later.',
          'error'
        );
      }
    } catch (err) {
      console.error(err);
      addSnackbar(
        'Sorry, something went wrong. Please try again later.',
        'error'
      );
    } finally {
      setLoading(false);
    }
  });

  if (successful) {
    return (
      <div className="thank-you">
        <h2 className="h4">Thank You for Submitting Your Story</h2>
        <div className="narrow">
          <p>
            Our team will review your submission and reach out to you should we
            need further information.
          </p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} data-netlify="true" name="share" action="/">
      <div className="form-fields">
        <div className="form-field">
          <Controller
            render={({ field: { onChange, value } }) => (
              <UniversitySelect
                value={value}
                onChange={onChange}
                getValues={getValues}
                name="university"
                id="university"
                label="University Name"
                options={universities || []}
                required
                errors={errors}
              />
            )}
            control={control}
            name="university"
          />
        </div>

        <SelectField
          register={register}
          getValues={getValues}
          errors={errors}
          name="title"
          label="Title"
          containerClassName="form-field form-field--quarter"
          required
        >
          <option value="">Title</option>
          <option value="Dr.">Dr.</option>
          <option value="Hon.">Hon.</option>
          <option value="Jr.">Jr.</option>
          <option value="Mr.">Mr.</option>
          <option value="Mrs.">Mrs.</option>
          <option value="Ms.">Ms.</option>
          <option value="Msgr.">Msgr.</option>
          <option value="Prof.">Prof.</option>
          <option value="Rev.">Rev.</option>
          <option value="Sr.">Sr.</option>
        </SelectField>
        <div className="form-field form-field--three-quarters">
          <div className="form-fields">
            <InputField
              register={register}
              getValues={getValues}
              type="text"
              errors={errors}
              name="firstName"
              label="First Name"
              containerClassName="form-field form-field--half"
              required
            />
            <InputField
              register={register}
              getValues={getValues}
              type="text"
              errors={errors}
              name="lastName"
              label="Last Name"
              containerClassName="form-field form-field--half"
              required
            />
          </div>
        </div>
        <InputField
          register={register}
          getValues={getValues}
          type="url"
          errors={errors}
          name="link"
          label="Article or YouTube Link (Optional)"
          placeholder="https://www.example.com"
          containerClassName="form-field form-field--half"
        />
        <InputField
          register={register}
          getValues={getValues}
          type="text"
          errors={errors}
          name="storyTitle"
          label="Story Title"
          containerClassName="form-field form-field--half"
          required
        />
        <TextAreaField
          register={register}
          getValues={getValues}
          errors={errors}
          name="story"
          label="Story Copy"
          containerClassName="form-field"
          required
        />
        <DropzoneField
          control={control}
          name="images"
          multiple
          containerClassName="form-field form-field--half"
          label="Submit your story images here."
          accept={{ 'image/*': [] }}
          images={images}
          setImages={setImages}
          errors={errors}
          maxFiles={5}
        />
        {/* Some hidden inputs so Netlify bot recognizes these fields */}
        {[...Array(5)].map((_, i) => (
          <input
            key={`image${i}`}
            type="file"
            name={`image${i}`}
            hidden
            style={{ display: 'none' }}
          />
        ))}
        <div className="form-field form-field--half">
          <div className="disclaimer">
            <button
              type="button"
              className="button button--icon button--icon--left"
              onClick={() => openDisclaimer()}
            >
              <MdInfo />
              <span>Disclaimer</span>
            </button>
          </div>
        </div>
        <div className="form-field form-field--footer">
          <FormSubmitButton
            loading={loading}
            title="Submit"
            className="button--alt"
          />
        </div>
      </div>
    </form>
  );
}
