import { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FiDownload } from 'react-icons/fi';

interface DropzoneProps extends DropzoneOptions {
  multiple?: boolean;
  containerClassName?: string;
  label: string;
  images: File[] | null;
  setImages: (images: File[]) => void;
  name: string;
  errors: FieldErrors<any>;
  value: FileList;
}
interface Props extends DropzoneOptions {
  control: Control;
  name: string;
  multiple?: boolean;
  containerClassName?: string;
  label: string;
  images: File[] | null;
  setImages: (images: File[]) => void;
  errors: FieldErrors<any>;
}

function Dropzone({
  multiple,
  containerClassName = '',
  label,
  images,
  setImages,
  errors,
  name,
  value,
  ...rest
}: DropzoneProps) {
  const [imageFiles, setImageFiles] = useState<
    (File & { preview: string })[] | null
  >(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple,
    onDrop: (files) => setImages(images ? [...images, ...files] : files),
    ...rest,
  });

  useEffect(() => {
    if (!images) {
      return;
    }
    setImageFiles(
      images.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, [images]);

  useEffect(
    () =>
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      () =>
        imageFiles?.forEach((file) => URL.revokeObjectURL(file.preview)),
    []
  );

  return (
    <div className={`${containerClassName} dropzone-wrapper`}>
      <div className={`dropzone ${errors[name] ? 'invalid' : ''}`}>
        <div {...getRootProps({ className: 'dropzone-input' })}>
          <label htmlFor="dropzone">
            <span className="label">
              {label}
              <br />
              <small style={{ color: 'var(--grey)' }}>
                Max. 5 files, 2 MB each
              </small>
            </span>

            <input id="dropzone" {...getInputProps()} />
          </label>
          {!images?.length && (
            <div className="label-instruction">
              <FiDownload />
              Add File
            </div>
          )}
        </div>
        {!!images?.length && (
          <div className="files">
            <ul>
              {imageFiles?.map((file, i) => (
                <li key={`${file.name}-${i}`}>
                  <div className="img">
                    <img
                      src={file.preview}
                      alt=""
                      width={32}
                      height={32}
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="remove"
                    onClick={() => {
                      if (!images) return;
                      const newImages = [...images];
                      newImages.splice(i, 1);
                      setImages(newImages);
                    }}
                  >
                    &times;
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {errors.images && <div className="error">{errors.images.message}</div>}
    </div>
  );
}

export function DropzoneField({
  control,
  name,
  multiple,
  images,
  setImages,
  errors,
  ...rest
}: Props) {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <Dropzone
          multiple={multiple}
          // onChange={(e) => {
          //   const target = e.target as HTMLInputElement;
          //   console.log(multiple ? target.files : target.files?.[0]);
          //   onChange(multiple ? target.files : target.files?.[0]);
          // }}
          images={images}
          errors={errors}
          name={name}
          setImages={setImages}
          value={value}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue=""
      rules={{
        required: true,
        validate: {
          maxFiles: (value: FileList) => {
            if (!multiple) {
              return value.length === 1;
            }
            return value.length <= 5 ? true : 'Please provide max. 5 images';
          },
          maxFileSize: (value: FileList) => {
            if (Array.from(value).some((f: File) => f.size > 2 * 1024 * 1024)) {
              return 'Each file size must be less than 2MB';
            }
            return true;
          },
        },
      }}
    />
  );
}
